.sect4-bg {
    position: relative;
    min-height: 100vh;
    width: 100%;
    
    background-size: cover;
    background-position: center;
}

.sect4-bg::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Прозрачный черный цвет */
}
.sect4-bg2 {
    position: relative;
    min-height: 100vh;
    width: 100%;
    background-size: cover;
    background-position: center;
}

.sect4-bg2::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Прозрачный черный цвет */
}

.sect4-bg3 {
    position: relative;
    min-height: 100vh;
    width: 100%;
    background-size: cover;
    background-position: center;
}

.sect4-bg3::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Прозрачный черный цвет */
}

.sect4-bg4 {
    position: relative;
    min-height: 100vh;
    width: 100%;
    background-size: cover;
    background-position: center;
}

.sect4-bg4::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Прозрачный черный цвет */
}

.sect4-bg5 {
    position: relative;
    min-height: 100vh;
    width: 100%;
    background-size: cover;
    background-position: center;
}

.sect4-bg5::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Прозрачный черный цвет */
}

.sect4-bg6 {
    position: relative;
    min-height: 100vh;
    width: 100%;
    background-size: cover;
    background-position: center;
}

.sect4-bg6::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Прозрачный черный цвет */
}

.sect4-bg7 {
    position: relative;
    min-height: 100vh;
    width: 100%;
    background-size: cover;
    background-position: center;
}

.sect4-bg7::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Прозрачный черный цвет */
}

.sect4-bg8 {
    position: relative;
    min-height: 100vh;
    width: 100%;
    background-size: cover;
    background-position: center;
}

.sect4-bg8::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Прозрачный черный цвет */
}
.sect4-bg9 {
    position: relative;
    min-height: 100vh;
    width: 100%;
    background-size: cover;
    background-position: center;
}

.sect4-bg9::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Прозрачный черный цвет */
}



.sect2__h1{
    padding-bottom: 50px;
}

.section2__txt{
    text-align: center;
    padding-top: 40px;
    color: white;
    z-index: 2;
    position: relative;
}
.section2__txt h1{
    font-size: clamp(40px, 4vw, 100px);
    font-weight: 600;

}
.header_p{
    font-size: clamp(10px,4vw, 15px);
}
.container-fluid2{
    width: 90%;
    margin: auto;
}
.sect_2_direct{
    color: rgb(255, 140, 0);
}

.section2__txt p {
    padding-bottom: 0px;
    font-style: italic;
    font-size: clamp(14px, 4vw, 20px);
}
@media screen and (max-width:1100px) {
    .sect2-bg{
        height: 100%;
    }

    
}
@media screen and (max-width:700px) {

    .sect2__h1{
        padding-bottom: 10px;
    }

    
}


/* aricle-card */
.article-image{
    width: 100%;
    position: relative;
    top: -10px;
}
.article-image2{
    top: -120px;
}
.article-image2{
    width: 300px !important;
}
.arcicle_imgs{
    overflow: hidden;
    max-width: 300px;
    max-height: 240px;
    height: 100%;
    display: block;
    justify-content: center;
    text-align: center;
    width: 100%;
}

.article-content{
    text-align: center;
    padding-top: 10px;
}
.article-content{
    max-width: 300px;
}





.container-fluid2{
    margin: auto;
    width: 90%;

}
.article-card{
    margin-top: 30px;
}
.article-content h2{
    font-size: 15px;

}
.article_link text{ 
    color: white;
}
